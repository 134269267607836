@import "app/styles/flexbox-grid";
@import "app/styles/screens";

.thanksContainer {
  @media (max-width: $screen-sm) {
    margin-top: 0px;
  }
  margin-top: 150px;

  .container {
    padding: 30px 0px 30px 0;
    max-width: 1024px;
    margin: 0 auto;

    @include medium {
      padding: 30px 0px;
      max-width: 1024px;
    }

    @include small {
      padding: 30px 0 30px;
      max-width: 768px;
    }

    @include xs {
      padding: 30px 0 30px;
      max-width: 375px;
    }
  }

  .row {
    @media (max-width: $screen-sm) {
      margin: 0 1rem;
    }
    margin-left: 0;
  }

  h2 {
    @media (max-width: $screen-sm) {
      font-size: 1.5rem;
    }
  }

  h3 {
    @media (max-width: $screen-sm) {
    }
    font-size: 1.375rem;
    margin-bottom: 2rem;
  }

  .thanks {
    @media (max-width: $screen-sm) {
      flex: 0 0 100%;
      margin: 0;
    }
    flex-grow: 1.47;
    flex-basis: 560px;
    margin: 0 1rem;
  }

  .thanksIntro {
    font-size: 1.125rem;
    padding: 0.75rem 0 1.5rem 0;
    color: #5c667a;
  }

  .serviceBlock {
    display: flex;
    border: 1px solid #d7dee2;
    padding: 1.5rem 2rem;
    border-radius: 5px;
    margin-bottom: 1rem;

    &:hover {
      box-shadow: 0px 0px 50px -20px rgba(107, 107, 107, 1);
    }

    svg {
      flex: 0 0 60px;
      height: 60px;
      margin-right: 2rem;
    }
    .svgPathDirectory {
      fill: #fff;
      stroke-width: 3;
      stroke: #98a0b0;
    }
    .svgPathMarketplace {
      stroke-width: 3;
      stroke: #98a0b0;
    }
    .svgPathResources {
      fill: #18c3bb;
    }
    .svgPathRealA {
      fill: #fff;
    }
    .svgPathRealB {
      fill: #98a0b0;
    }

    h4 {
      font-size: 1rem;
      font-weight: 600;
    }
    .serviceText p {
      font-size: 0.875rem;
    }
  }

  .helpContainer {
    flex-grow: 1;
    flex-basis: 380px;
    margin: 0 1rem;
    @media (max-width: $screen-sm) {
      flex-grow: 0;
      display: none;
    }
  }
}
